import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"

export function ErrorAlert(props: object) {
  const { t } = useTranslate()

  return (
    <MBanner type="error" {...props}>
      {t(langKeys.failed_something_went_wrong)}
    </MBanner>
  )
}
